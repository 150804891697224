import React, { useState } from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { Heading } from './Heading'
import { ModalCloseButton } from './ModalCloseButton'
import { Text } from './Text'
import { ButtonLink } from './ButtonLink'

import { useFadedDiamondBackground } from '../hooks/useFadedDiamondBackground'
import { useModal } from '../hooks/useModal'

import * as styleRefs from './ReportLostCard.treat'

type CardOptionProps = BoxProps & {
  isActive?: boolean
}

const CardOption = ({
  children,
  isActive = false,
  onClick,
  ...props
}: CardOptionProps) => {
  return (
    <Box
      component="button"
      onClick={onClick}
      className={styleRefs.options}
      {...props}
      styles={{
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: isActive ? 'beige80' : 'white',
        ...props.styles,
      }}
    >
      <Heading
        level={5}
        variant="sansCapsB"
        component="div"
        className={styleRefs.optionsText}
      >
        {children}
      </Heading>
    </Box>
  )
}

export const ReportLostCardModal = () => {
  const [activeCardIdx, setActiveCardIdx] = useState<0 | 1>(0)
  const backgroundClassName = useFadedDiamondBackground('beige100', 'top')
  const { closeModal } = useModal()

  const showDebit = () => setActiveCardIdx(0)
  const showCredit = () => setActiveCardIdx(1)

  const isShowingDebit = activeCardIdx === 0

  return (
    <Box
      styles={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: [8, 12],
        paddingBottom: [8, 12],
        backgroundColor: 'beige100',
        position: 'relative',
      }}
      className={backgroundClassName}
    >
      <ModalCloseButton
        onClick={closeModal}
        styles={{ position: 'absolute', top: 0, right: 0 }}
      />

      <Box styles={{ marginBottom: 8 }}>
        <Heading
          level={3}
          variant="sansC"
          styles={{
            color: 'beige40',
            fontWeight: 'light',
            marginBottom: 5,
          }}
        >
          Don't Worry
        </Heading>
        <Text
          variant="sans-19-22"
          styles={{ color: 'brown20', marginBottom: 6 }}
        >
          We're here for you, 24/7.
        </Text>
        <Heading level={4} variant="sansCapsB" styles={{ color: 'fuschia30' }}>
          If your card is lost or stolen, <br />
          or if you did not receive your card:
        </Heading>
      </Box>

      <Box
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
        }}
      >
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: [null, '6/12'],
            marginBottom: [8, 0],
          }}
        >
          <Box
            styles={{
              display: 'flex',
              marginBottom: 6,
              backgroundColor: 'white',
              padding: 1,
              color: 'fuschia30',
            }}
            className={styleRefs.options}
          >
            <CardOption isActive={isShowingDebit} onClick={showDebit}>
              Debit
            </CardOption>
            <CardOption isActive={activeCardIdx === 1} onClick={showCredit}>
              Credit
            </CardOption>
          </Box>

          {isShowingDebit ? (
            <>
              <Heading level={5} variant="sansF" styles={{ marginBottom: 3 }}>
                Mon-Fri 8am-5pm, call:
              </Heading>
              <ButtonLink
                variant="red"
                styles={{ marginBottom: 6 }}
                href="tel:8085287800"
                linkProps={{ skipExternalCheck: true }}
              >
                (808) 528-7800
              </ButtonLink>

              <Heading level={5} variant="sansF" styles={{ marginBottom: 3 }}>
                After hours, call:
              </Heading>
              <ButtonLink
                variant="red"
                styles={{ marginBottom: 3 }}
                href="tel:8005282273"
                linkProps={{ skipExternalCheck: true }}
              >
                (800) 528-2273
              </ButtonLink>
              <Text styles={{ color: 'brown20', marginBottom: 6 }}>
                Domestic
              </Text>

              <ButtonLink
                variant="red"
                styles={{ marginBottom: 3 }}
                href="tel:8126479794"
                linkProps={{ skipExternalCheck: true }}
              >
                (812) 647-9794
              </ButtonLink>
              <Text styles={{ color: 'brown20' }}>International</Text>
            </>
          ) : (
            <>
              <ButtonLink
                variant="red"
                styles={{ marginBottom: 3 }}
                href="tel:866522-8855"
                linkProps={{ skipExternalCheck: true }}
              >
                (866) 552-8855
              </ButtonLink>
              <Text styles={{ color: 'brown20', marginBottom: 6 }}>
                Domestic
              </Text>

              <ButtonLink
                variant="red"
                styles={{ marginBottom: 3 }}
                href="tel:7014611922"
                linkProps={{ skipExternalCheck: true }}
              >
                (701) 461-1922
              </ButtonLink>
              <Text styles={{ color: 'brown20' }}>International</Text>
            </>
          )}
        </Box>

        <Box
          styles={{
            width: '1px',
            backgroundColor: 'beige40',
            opacity: 25,
            marginLeft: 8,
            marginRight: 8,
            display: ['none', 'block'],
          }}
        />

        <Box
          styles={{
            color: 'brown20',
            paddingTop: 3,
            paddingBottom: 3,
            maxWidth: '50ch',
            alignSelf: [null, 'center'],
            width: [null, '6/12'],
          }}
        >
          <Text variant="sans-16" styles={{ marginBottom: 6 }}>
            Our staff will never contact you on an unsolicited basis and request
            your electronic banking credentials. Do not give these credentials
            to anyone. If you are contacted by someone claiming to be a Hawaii
            National Bank representative, do not give them account information
            or credentials.
          </Text>
          <Text>
            Contact our Electronic Banking & Payments Department at (808)
            528-7800 immediately if you notice suspicious account activity or
            experience any security-related events.
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
